.GitHub {
  width: 100%;
  height: 100%;
  background-color: var(--black-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  position: relative;
  border-radius: 30px;
  transition: all 500ms ease-in-out 0s;
}
