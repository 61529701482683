.Row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Row > div {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin: 5px;
  background-color: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-transform: uppercase;
}

.correct {
  --background-color: #a3d2fc;
  animation: flip 0.5s ease forwards;
  -ms-animation: flip 0, 5s ease forwards;
}
.almost {
  --background-color: #ef98a8;
  animation: flip 0.5s ease forwards;
  -ms-animation: flip 0.5s ease forwards;
}
.wrong {
  --background-color: var(--wordle-secondary-bg-color);
  animation: flip 0.5s ease forwards;
  -ms-animation: flip 0.5s ease forwards;
}

.Row > div:nth-child(2) {
  animation-delay: 0.2s;
  -ms-animation-delay: 0.2s;
}
.Row > div:nth-child(3) {
  animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
}
.Row > div:nth-child(4) {
  animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
}
.Row > div:nth-child(5) {
  animation-delay: 0.8s;
  -ms-animation-delay: 0.8s;
}
.Row.Row-current .filled {
  animation: bounce 0.2s ease-in-out forwards;
  -ms-animation: bounce 0.2s ease-in-out forwards;
}

.Row.Row-current.invalid {
  animation: invalid 0.3s ease forwards;
  -ms-animation: invalid 0.3s ease forwards;
}

.Row.Row-current .winner {
  animation: winner 0.5 ease forwards;
  animation-delay: 1.3s;
}

/* animations */
@keyframes flip {
  0% {
    transform: rotateX(0);
    background-color: #2c2c2c;
  }
  45% {
    transform: rotateX(90deg);
    background-color: #2c2c2c;
  }
  55% {
    transform: rotateX(90deg);
    background-color: var(--background-color);
  }
  100% {
    transform: rotateX(0);
    background-color: var(--background-color);
  }
}

@-ms-keyframes flip {
  0% {
    -ms-transform: rotateX(0);
    background-color: #2c2c2c;
  }
  45% {
    -ms-transform: rotateX(90deg);
    background-color: #2c2c2c;
  }
  55% {
    -ms-transform: rotateX(90deg);
    background-color: var(--background-color);
  }
  100% {
    -ms-transform: rotateX(0);
    background-color: var(--background-color);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@-ms-keyframes bounce {
  0% {
    -ms-transform: scale(1);
  }
  50% {
    -ms-transform: scale(1.2);
  }
  100% {
    -ms-transform: scale(1);
  }
}

@keyframes invalid {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-2.5px);
  }
  80% {
    transform: translateX(2.5px);
  }
  100% {
    transform: translateX(0px);
  }
}

@-ms-keyframes invalid {
  0% {
    -ms-transform: translateX(0px);
  }
  20% {
    -ms-transform: translateX(-5px);
  }
  40% {
    -ms-transform: translateX(5px);
  }
  60% {
    -ms-transform: translateX(-2.5px);
  }
  80% {
    -ms-transform: translateX(2.5px);
  }
  100% {
    -ms-transform: translateX(0px);
  }
}

@keyframes winner {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(1px);
  }
  60% {
    transform: translateY(-5px);
  }
  80% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}
