.ClockWidget {
  width: 100%;
  height: 100%;
  background-color: var(--secondary-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  position: relative;
  border-radius: 30px;
  transition: all 500ms ease-in-out 0s;
}

.ClockWidget_button {
  background-color: #2c2c2c;
  color: #ffffff;
  cursor: default;
}

.ClockWidget_button:hover {
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 0px 6px;
}

.react-clock__face {
  border-color: var(--main-color) !important;
}

.react-clock__mark__body {
  background-color: var(--main-color) !important;
}

.react-clock__hand__body {
  background-color: var(--main-color) !important;
}

.react-clock__second-hand__body {
  background-color: red !important;
}
