.ThemeMode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 50px;
  border-radius: 30px;
}

.ThemeMode_toggler {
  height: 50px;
  width: 80px;
  background-color: #2c2c2c;
  box-shadow: transparent 0px 0px 0px 2px inset;
  border-radius: 100px;
  display: flex;
  align-items: center;
  position: relative;
}

.ThemeMode_toggler_switch {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 6px;
  border-radius: 30px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ThemeMode_toggler_switch_icon {
  fill: #2c2c2c;
}
