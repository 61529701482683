.Navbar {
  max-width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: var(--nav-bg-color);
  border-radius: 30px;
  position: fixed;
  z-index: 50000;

  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);

  transition: 0.2s ease-out 0s;
}

.Navbar_item {
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 30px;
  margin: 5px;
  z-index: 1;
  cursor: pointer;
  color: var(--main-color);
}

.NavBar_item_hover-bg {
  background-color: var(--nav-hover-bg-color);
  border-radius: 50px;
  position: absolute;
  width: 57.9375px;
  height: 38px;
  left: 5px;
  transition: all 500ms ease-in-out 0s;
}

@media only screen and (max-width: 920px) {
  .Navbar {
    bottom: 30px;
  }
}

@media only screen and (min-width: 920px) and (max-width: 1320px) {
  .Navbar {
    bottom: 30px;
  }
}

@media only screen and (min-width: 1320px) {
  .Navbar {
    bottom: 30px;
  }
}

@media only screen and (min-height: 1300px) and (min-width: 920px) {
  .Navbar {
    bottom: 40px;
  }
}
