.CryptoProject {
  width: 100%;
  height: 100%;
  position: relative;

  border-radius: 30px;
  overflow: hidden;
  background: #212531;
  background: linear-gradient(to right bottom, #f598a8 50%, #a2d2ff 50%);
}

.CryptoProject_image {
  height: 400px;
  margin-top: 50px;
  margin-left: 50px;
  transition: all 500ms ease-in-out 0s;
  pointer-events: none;
  transform: rotate3d(1, 1, 1, -30deg);
  border-radius: 15px;
}

.CryptoProject:hover .CryptoProject_image {
  transform: translate3d(-375px, 0, 0);
  margin-top: 92.5px;
}
