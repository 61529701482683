.PortfolioProject {
  width: 100%;
  height: 100%;
  position: relative;

  border-radius: 30px;
  overflow: hidden;
  background: #212531;
  background: linear-gradient(
    to right bottom,
    var(--black-bg-color) 50%,
    #f6edb2 50%
  );
}

.PortfolioProject_image {
  height: 400px;
  margin-top: 100px;
  margin-left: 200px;
  transition: all 500ms ease-in-out 0s;
  pointer-events: none;
  transform: rotate3d(1, 1, 1, -30deg);
  border-radius: 15px;
}

.PortfolioProject:hover .PortfolioProject_image {
  transform: translate3d(-150px, 0, 0);
  margin-top: -120px;
}

@media only screen and (max-width: 920px) {
  .PortfolioProject_image {
    margin-top: 50px;
    margin-left: 100px;
    height: 300px;
  }
  .PortfolioProject:hover .PortfolioProject_image {
    transform: translate3d(-120px, 0, 0);
    margin-top: -150px;
  }
}

@media only screen and (min-width: 920px) {
  .PortfolioProject_image {
    margin-top: 0px;
    margin-left: 100px;
  }
}

@media only screen and (min-width: 1320px) {
  .PortfolioProject_image {
    margin-top: 60px;
    margin-left: 160px;
  }
}
