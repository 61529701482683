.LinkedIn {
  width: 100%;
  height: 100%;
  background-color: #a2d2ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  position: relative;
  border-radius: 30px;
}
