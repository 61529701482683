.WordleProject {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 50px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.WordleProject_main-heading_container {
  display: flex;
  padding: 3px;
  margin: 5px;
  border-radius: 100px;
  transition: 0.2s ease-out 0s;
  background: linear-gradient(45deg, #f598a8, #f6edb2);
  margin-bottom: 1.5rem;
}

.WordleProject_main-heading_text {
  text-decoration: none;
  border-radius: 100px;
  color: var(--main-color);
  background: var(--button-color);
  transition: 0.2s ease-out 0s;
  padding: 4px 14px;
}

.WordleProject_heading {
  margin-bottom: 2rem;
}

.WordleProject_button {
  background-color: #2c2c2c;
  color: #ffffff;
}

.WordleProject_button:hover {
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 0px 6px;
}

.button.top-right {
  top: 15px;
  right: 15px;
  bottom: auto;
  left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0;
  width: 40px;
  height: 40px;
}

.button_top-right_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-right_left-icon {
  width: 15px;
  height: 15px;
  padding-right: 2px;
}

.WordleProject_pop-out {
  position: absolute;
  top: 15px;
  left: 15px;
  width: calc(100% - 27.1px);
  padding: 15px 20px;
  border-radius: 5px;
  border-top-right-radius: 23px;
  background-color: var(--wordle-module-bg-color);
  z-index: -1;
  transition: all 500ms ease 0s;
  animation: fadeIn 0.4s ease forwards;
  overflow: hidden;
  max-height: 91px;
}

.pop-out_container {
  display: flex;
  align-items: center;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
  color: var(--main-color);
}

.pop-out_block {
  min-width: 15px;
  width: 15px;
  height: 15px;
  border-radius: 2.5px;
  margin-right: 0.5rem;
  border: 1px solid #ffffff;
}

.pop-out_block_text {
  white-space: nowrap;
}

.pop-out_block.blue {
  background-color: #a2d2ff;
}
.pop-out_block.peach {
  background-color: #f598a8;
}
.pop-out_block.gray {
  background-color: #444444;
}

@keyframes fadeIn {
  0% {
    right: 15px;
    left: auto;
    width: 40px;
  }
  100% {
    right: 15px;
    left: auto;
    width: calc(100% - 30px);
  }
}
